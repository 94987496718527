<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.7324 6.08728L13.5664 5.25324C14.1802 4.63951 14.1802 3.64024 13.5664 3.02651L12.457 1.91708C11.8433 1.30336 10.844 1.30336 10.2303 1.91708L9.39624 2.75112L12.7324 6.08728ZM8.27894 3.86055L1.04797 11.0994V14.4355H4.38413L11.6151 7.20458L8.27894 3.86055Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconPen'
}
</script>
